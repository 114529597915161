import { fade, lighten } from 'material-ui/utils/colorManipulator';
import { presonoTeal, grey300, grey400, grey500, white, darkBlack, black, lightBlue300, pink300, amber300 } from '../values/colors';
import spacing from '../values/spacing';

export default {
  appBar: {
    color: white,
  },
  spacing,
  fontFamily: 'Roboto, sans-serif',
  fonts: [
    {
      name: 'Roboto',
      weights: [300, 400, 500],
    },
  ],
  palette: {
    primary1Color: presonoTeal,
    primary2Color: presonoTeal,
    primary3Color: grey400,
    accent1Color: lighten(presonoTeal, 0.4),
    accent2Color: presonoTeal,
    accent3Color: grey500,
    textColor: darkBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: presonoTeal,
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: black,
    listHighlight: fade(presonoTeal, 0.15),
    notificationColor: lightBlue300,
    feedbackColor: pink300,
    messageColor: amber300,
  },
  logolink: 'url("/assets/presono-logo.png")',
  logosize: '52',
  logosizeOuter: '64',
};
