import storage from './storage';

export default {
  en: {
    sign_up_for_free: 'Sign up for free!',
    test_all_features: 'Try all features 60 days for free!',
    including_trial: 'including a 60-day Professional Plan trial',
    i_agree_to_presonos: 'I agree to presonos %{link}.',
    tos: 'Terms of Service',
    i_agree_to_presonos_pp: 'I agree to presonos %{link}.',
    privacy: 'Privacy Policy',
    inform_me_about_news: 'Inform me about news & highlights.',
    sign_up_now: 'Sign up now',
    signing_up: 'Signing up',
    sign_up_anyway: 'Sign up anyway',
    you_already_have_an_account: 'You already have an account?',
    login_here: 'Login here',
    thank_you_for_signing_up: 'Thank you for signing up!',
    have_fun: 'Have fun exploring the next generation of presentation!',
    we_have_sent_you_an_email: 'We have sent you an email',
    sent_email: 'We have sent you an email.',
    check_inbox: 'Please check your inbox and verify your account.',
    email_not_set: 'Email is required.',
    email_is_not_valid: 'The email address is not valid.',
    tos_and_privacy_not_accepted: 'Our Terms of Service and Privacy Policy need to be accepted.',
    user_registerd_but_not_active: 'This user is already registered, please check your inbox and verify your email.',
    user_already_exists: 'The email address is already in use.',
    general_error: 'An error occurred, please try again later.',
    has_config: 'Looks like you might already have an account.',
    go_to_stack: 'Use other domain',
    time_and_cost_savings: 'Save time & costs when creating and managing presentations',
    always_latest_content: 'Always up-to-date content & design',
    optimal_analysis_of_data: 'Comprehensive analysis of data and usage',
    i_agree_to_presonos_ToS_and_PP: 'I agree to the %{link_ToS} and %{link_PP}',
    privacy_policy_link: 'https://presono.com/en/privacyPolicy',
    terms_link: 'https://presono.com/en/terms/',
    test_thirty_days_for_free: 'try 60 days free',
    end_of_free_trial: 'Your trial ends automatically after 60 days. You can either use the free basic version or subscribe to a paid package that fits your needs.',
  },
  de: {
    sign_up_for_free: 'Kostenlos registrieren!',
    test_all_features: 'Alle Features 60 Tage kostenlos testen!',
    including_trial: 'beinhaltet ein 60-Tage-Premium-Abo',
    i_agree_to_presonos: 'Ich stimme den %{link} zu.',
    tos: 'AGBs',
    i_agree_to_presonos_pp: 'Ich stimme der %{link} zu.',
    privacy: 'Datenschutzerklärung',
    inform_me_about_news: 'Informiert mich über News und Highlights.',
    sign_up_now: 'Jetzt Registrieren',
    signing_up: 'Registriere',
    sign_up_anyway: 'Trotzdem Registrieren',
    you_already_have_an_account: 'Du hast bereits einen Account?',
    login_here: 'Jetzt einloggen',
    thank_you_for_signing_up: 'Vielen Dank für deine Registrierung!',
    have_fun: 'Viel Spaß beim Erkunden der nächsten Generation der Präsentation!',
    we_have_sent_you_an_email: 'Wir haben dir gerade eine E-Mail zugesandt',
    sent_email: 'Wir haben dir gerade eine E-Mail zugesandt.',
    check_inbox: 'Bitte prüfe deinen Posteingang, um die Registrierung abzuschließen.',
    email_not_set: 'Eine E-Mail-Adresse ist erforderlich.',
    email_is_not_valid: 'Deine E-Mail-Adresse ist nicht gültig.',
    tos_and_privacy_not_accepted: 'Die AGBs und Datenschutzerklärung müssen akzeptiert werden.',
    user_registerd_but_not_active: 'Diese E-Mail-Adresse ist bereits registriert, bitte überprüfe deinen Posteingang, um die Registrierung abzuschließen.',
    user_already_exists: 'Ein Benutzer mit dieser E-Mail-Adresse existiert bereits.',
    general_error: 'Es ist ein Fehler aufgetreten, bitte versuche es später noch einmal.',
    has_config: 'Möglicherweise hast du bereits einen Account.',
    go_to_stack: 'Andere Domain verwenden',
    time_and_cost_savings: 'Zeit- und Kostenersparnis beim Erstellen und Verwalten von Präsentationen',
    always_latest_content: 'Immer aktuelle und stimmige Inhalte & Desgins',
    optimal_analysis_of_data: 'Optimale Analyse der Daten und Nutzung',
    i_agree_to_presonos_ToS_and_PP: 'Ich stimme den %{link_ToS} und der %{link_PP} zu',
    privacy_policy_link: 'https://presono.com/privacyPolicy/',
    terms_link: 'https://presono.com/terms/',
    test_thirty_days_for_free: '60 Tage kostenlos testen',
    end_of_free_trial: 'Nach den 60 Tagen endet dein Probe-Abo automatisch. Du kannst entweder die kostenfreie Basic-Variante weiterverwenden oder ein kostenpflichtiges Abo abschließen, um alle Vorteile zu nutzen.'
  },
};

export const defaultLanguage = 'en';

export const languages = {
  en: {
    country: 'GB',
    locale: 'en',
    display_name: 'English',
  },
  de: {
    country: 'DE',
    locale: 'de',
    display_name: 'Deutsch',
  },
  moo: {
    country: '_',
    locale: '_',
    display_name: 'Cow',
  },
};

export const isLanguageAvailable = lang => Object.prototype.hasOwnProperty.call(languages, lang);

export const guessLanguage = () => {
  const override = storage.get(storage.key.OVERRIDE_LOCALE) || null;
  if (override && isLanguageAvailable(override)) return override;

  let lang = window.navigator.userLanguage || window.navigator.language;
  let shortLang;
  if (lang.length === 5) {
    // convert lang format from en-US to en_US, ensure correct case
    lang = lang.replace('-', '_');
    const split = lang.split('_');
    if (split.length === 2) {
      lang = `${split[0].toLowerCase()}_${split[1].toUpperCase()}`;
    }
    shortLang = split[0].toLowerCase(); // if en-US is set, also try en
  } else {
    lang = lang.toLowerCase();
    shortLang = lang;
  }

  if (isLanguageAvailable(lang)) {
    return lang;
  } else if (isLanguageAvailable(shortLang)) {
    return shortLang;
  }

  return defaultLanguage;
};
