/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';

import Checkbox from 'material-ui/Checkbox';
import MailChecker from 'mailchecker';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';

import ContentClear from 'material-ui/svg-icons/content/clear';
import store from './store';

import RichI18n from './helpers/RichI18n';
import presonoBase from './values/presono-base';
import {
  presonoTeal, accentColor, veryFaintAccentColor, accentColor25, accentColor15, deepOrange800, fainterPresonoTeal,
} from './values/colors';
import { LoginTextField, MinimalUILanguageSwitcher } from './Components/presonoUI';
import ProgressBar from './Components/ProgressBar';
import { guessLanguage } from './helpers/localisation';
import Check from './Components/icons/Check';
import MailIcon from './Components/icons/MailIcon';


const DEFAULT_URL = 'my.presono.com';
const STACK_CHECK_URL = 'https://wmgtdq1duk.execute-api.eu-west-1.amazonaws.com/prd/checkemail';


const DevStacks = [
  { url: DEFAULT_URL, name: 'Production (MY)' },
  { url: 'stg.presono.com', name: 'Staging' },
  { url: 'd24i0r2tbymckt.cloudfront.net', name: 'BB' }, // bb
  { url: 'dvhawawzkvym5.cloudfront.net', name: 'PK' }, // pk
  { url: 'd2l9xg2b6upw65.cloudfront.net', name: 'AK' }, // ak
  { url: 'dd12o2cp1m5zo.cloudfront.net', name: 'LI' }, // limes
  { url: null, name: 'CUSTOM' },
];


const TOKEN_ALREADY_EXISTS_ERROR = 'A token for this email already exists';
const USER_ALREADY_EXISTS_ERROR = 'A User with that email already exists';

const presonoBaseTheme = getMuiTheme(presonoBase);

function getUrlVars() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}

const style = {
  headerLogo: {
    backgroundImage: 'url(/assets/media/logo.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  primaryButton: {
    width: '100%',
    height: 46,
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,
    borderRadius: '2px',
  },
  primaryButtonLabel: {
    fontSize: '14px',
    padding: 0,
  },
  secondaryButton: {
    border: `1px solid ${accentColor15}`,
    borderRadius: '2px',
    boxShadow: 'none',
  },
  actionsWrapper: {
    textAlign: 'center',
  },
  progressBar: {
    height: 8,
    transition: 'transform 0.2s ease-in',
    width: '100%',
  },
  checkBox: {
    width: 'auto',
    transform: 'translateY(-1px)',
  },
  checkIconStyle: {
    fill: presonoTeal,
    marginRight: 8,
    height: '18.5px',
    width: '18.5px',
    flexShrink: 0,
  },
  mailIconStyle: {
    display: 'block',
    height: '115px',
    width: '115px',
    marginLeft: 'auto',
    marginRight: 'auto',
    fill: presonoTeal,
    marginBottom: '25px',
  },
};

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,

      email: '',
      emailError: null,

      tos: false,
      tosError: null,

      privacy: false,

      inform: false,

      error: null,

      stack: null,

      registerUrl: DEFAULT_URL,

      customUrlSelected: false,
      devUrlSelector: DevStacks[0].url,
      customUrl: DevStacks[0].url,
    };

    this.devMode = getUrlVars().dev === 'true';
  }

  componentDidMount() {
    ReactPixel.init('236304444294348', {}, { debug: false, autoConfig: true });
  }

  gtag_report_conversion = () => {
    try {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-961903471/_mzKCP63yOUBEO_21coD',
        'event_callback': () => {},
      });
    } catch (err) {
      console.error(err);
    }
  }

  getErrorText = (error) => {
    if (error && error.response && error.response.data && error.response.data.error_msg) {
      if (error.response.data.error_msg === TOKEN_ALREADY_EXISTS_ERROR) return I18n.t('user_registerd_but_not_active');
      if (error.response.data.error_msg === USER_ALREADY_EXISTS_ERROR) return I18n.t('user_already_exists');
    }

    return I18n.t('general_error');
  }

  sendData = () => {
    const {
      email, inform, registerUrl, customUrl,
    } = this.state;
    this.setState({ loading: true });
    const data = {
      email: email.trim(),
      receivePromotions: inform,
      language: guessLanguage(),
      referrer: window.location.hostname,
    };
    const url = this.devMode ? customUrl : registerUrl;
    // eslint-disable-next-line no-console
    console.log('USING url', url);
    axios.post(`https://${url}/api/emailtoken`, data)
      .then(() => {
        ReactPixel.track('Lead', { em: data.email });
        this.gtag_report_conversion();
        this.setState({ loading: false, success: true });
      })
      .catch((error) => {
        this.setState({ loading: false, error: this.getErrorText(error) });
        // console.log(error);
      });
  }

  stackCheck = async () => {
    const { email, registerUrl } = this.state;
    this.setState({ loading: true });

    try {
      const stackResponse = await axios.get(STACK_CHECK_URL, {
        params: {
          email: email.split('@').pop(),
          stack: registerUrl,
        },
      });

      return stackResponse.data;
    } catch (error) {
      // just skip this if the email check fails
      return null;
    }
  }


  onSubmit = async (event) => {
    event.preventDefault();
    const {
      email, tos, privacy, stack,
    } = this.state;
    const trimedEmail = email.trim();
    if (!trimedEmail) {
      return this.setState({ emailError: I18n.t('email_not_set') });
    }

    if (!MailChecker.isValid(trimedEmail)) {
      return this.setState({ emailError: I18n.t('email_is_not_valid') });
    }

    if (!tos || !privacy) {
      return this.setState({ tosError: !tos, error: I18n.t('tos_and_privacy_not_accepted') });
    }
    ReactPixel.pageView();

    // if stack is empty and we never checked for a stack
    if (!stack) {
      const repsonse = await this.stackCheck();
      // if we get a response, and it has a config we inform the user, otherwise just send thecdata
      if (repsonse && repsonse.has_config && repsonse.stack_href) {
        return this.setState({ stack: repsonse.stack_href, loading: false });
      }
    }

    return this.sendData();
  };


  handleChange = (e, newValue) => {
    this.setState({
      email: newValue,
      emailError: null,
      error: null,
    });
  }


  renderGeneralError = (error, errorStyle = {}) => (
    <div
      style={{
        color: deepOrange800,
        fontSize: 14,
        opacity: error ? 1 : 0,
        height: error ? 'auto' : 0,
        maxHeight: error ? 200 : 0,
        transition: 'all 0.3s ease-out',
        textAlign: 'center',
        ...errorStyle,
      }}
    >
      {error}
    </div>
  )

  renderStackInformation = () => {
    const { stack } = this.state;

    if (!stack) return <div />;

    return (
      <div
        style={{
          fontSize: 14,
          opacity: stack ? 1 : 0,
          height: stack ? 'auto' : 0,
          maxHeight: stack ? 200 : 0,
          transition: 'all 0.3s ease-out',
          textAlign: 'center',
          background: fainterPresonoTeal,
          border: `1px solid ${presonoTeal}`,
          padding: 10,
          borderRadius: 4,
          marginTop: '13px',
          marginBottom: '21px',
        }}
      >
        <div>{ I18n.t('has_config')}</div>
        <FlatButton
          style={{ height: 'auto', lineHeight: '16px' }}
          labelStyle={{ padding: 0, borderRadius: '2px' }}
          label={I18n.t('go_to_stack')}
          labelColor={presonoTeal}
          href={stack}
          hoverColor="transparent"
          primary
        />
      </div>
    );
  }

  renderSuccess = () => (
    <div className="login-inner-content login-inner-content-header-success">
      <div className="login-heading-success">
        <h1>{I18n.t('thank_you_for_signing_up')}</h1>
        <h2>
          {I18n.t('have_fun')}
        </h2>
        <MailIcon style={style.mailIconStyle} />
        <div className="login-text-info">{I18n.t('sent_email')}</div>
        <div className="login-text-info login-text-check-inbox">{I18n.t('check_inbox')}</div>
      </div>
    </div>
  )

  renderHeader = () => {
    const headerClass = 'login-inner-content-header';
    return (
      <div className={`login-inner-content ${headerClass}`}>
        <div className="login-header-logo" style={style.headerLogo} />
        <MinimalUILanguageSwitcher ref={(el) => { if (!this.langSwitcher) this.langSwitcher = el; }} />
      </div>
    );
  }


  renderDevMode = () => {
    const entries = DevStacks.map((devStack) => (
      <MenuItem
        key={devStack.url}
        value={devStack.url}
        primaryText={
          devStack.name
        }
      />
    ));

    return (
      <div style={{ display: 'flex', paddingLeft: 30, backgroundColor: 'salmon' }}>
        {
          !this.state.customUrlSelected && (
            <SelectField
              value={this.state.devUrlSelector}
              onChange={(event, index, value) => {
                if (value === null) {
                  this.setState({ customUrlSelected: true, customUrl: '' });
                } else {
                  this.setState({ devUrlSelector: value, customUrl: value });
                }
              }}
              floatingLabelText="Stack"
            >
              {entries}
            </SelectField>
          )
        }
        {
          this.state.customUrlSelected && (
            <div>
              <TextField
                hintText="stg.presono.com"
                floatingLabelText="Custom URL"
                onChange={(_, t) => this.setState({ customUrl: t.replace(/https?:\/\//, '').replace(/\/$/, '') })}
              />

              <IconButton
                onClick={() => this.setState({
                  customUrlSelected: false,
                  devUrlSelector: DevStacks[0].url,
                  customUrl: DevStacks[0].url,
                })}
              >
                <ContentClear />
              </IconButton>
            </div>
          )
        }

      </div>
    );
  }


  renderContent = () => {
    const {
      tos, tosError, privacy, inform, email, emailError, error, success,
    } = this.state;

    if (success) return this.renderSuccess();

    let tosColor = tos ? presonoTeal : accentColor;

    if (tosError) tosColor = deepOrange800;

    const actionWrapperClass = this.state.stack ? 'action-wrapper-stack' : 'action-wrapper-nostack';

    return (
      <form onSubmit={this.onSubmit} className="login-inner-content">
        <div className="login-heading">
          <img src="/assets/media/devices.png" alt="devices" />
          <h1>{I18n.t('test_all_features')}</h1>
          <div className="login-inner-content-list">
            <Check style={style.checkIconStyle} />
            {I18n.t('time_and_cost_savings')}
          </div>
          <div className="login-inner-content-list">
            <Check style={style.checkIconStyle} />
            {I18n.t('always_latest_content')}
          </div>
          <div className="login-inner-content-list">
            <Check style={style.checkIconStyle} />
            {I18n.t('optimal_analysis_of_data')}
          </div>
        </div>
        <div>
          <div className="register-email-tf-email">
            <LoginTextField
              floatingLabelText={I18n.t('email')}
              errorText={emailError}
              onClick={() => { if (this.langSwitcher) this.langSwitcher.closePopover(); }}
              onChange={this.handleChange}
              value={email}
              style={{ width: '100%', boxSizing: 'border-box', marginBottom: '0px' }}
              className="register-email-tf-email"
            />
          </div>
          <div style={{ marginbottom: 12 }}>
            <div className="login-checkbox-wrapper">
              <Checkbox
                checked={tos && privacy}
                key="tos"
                onCheck={(e) => {
                  // if checked with keyboard space the event is fired twice
                  if (e.type === 'keydown') return;
                  this.setState({ tos: !this.state.tos, tosError: false, error: null, privacy: !this.state.privacy });
                }}
                style={style.checkBox}
                iconStyle={{ fill: tosColor, marginRight: 6 }}
                className="login-checkbox-label"
              />
              <span className="login-checkbox-label">
                {RichI18n('i_agree_to_presonos_ToS_and_PP',
                  {
                    link_ToS: <a
                      tabIndex="-1"
                      key="tos"
                      className="inline-link"
                      href={I18n.t('terms_link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {I18n.t('tos')}
                    </a>,
                    link_PP: <a
                      tabIndex="-1"
                      key="privacy"
                      className="inline-link"
                      href={I18n.t('privacy_policy_link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {I18n.t('privacy')}
                    </a>,
                  })}
              </span>
            </div>
            <div className="login-checkbox-wrapper">
              <Checkbox
                checked={inform}
                key="inform"
                onCheck={(e) => {
                  if (e.type === 'keydown') return;
                  this.setState({ inform: !this.state.inform });
                }}
                style={style.checkBox}
                iconStyle={{ fill: this.state.inform ? presonoTeal : accentColor, marginRight: 6 }}
                className="register-email-cb-inform login-checkbox-label"
              />
              <span className="login-checkbox-label">{I18n.t('inform_me_about_news')}</span>
            </div>
          </div>

          {this.renderGeneralError(error)}
          {this.renderStackInformation()}

        </div>
        <div style={{ ...style.actionsWrapper }} className={`${actionWrapperClass}`}>
          <div className="register-email-btn-div">
            <RaisedButton
              type="submit"
              disabledBackgroundColor={veryFaintAccentColor}
              disabledLabelColor={accentColor}
              label={this.state.loading ? I18n.t('signing_up') : this.state.stack ? I18n.t('sign_up_anyway') : I18n.t('test_thirty_days_for_free')}
              primary
              disabled={this.state.loading}
              style={style.primaryButton}
              labelStyle={style.primaryButtonLabel}
            />
            <ProgressBar
              value={100}
              style={{ ...style.progressBar, transform: `translateY(${this.state.loading ? '-2px' : '-30px'})` }}
              color={presonoTeal}
              active
              noDrop
            />
          </div>

        </div>
        <div className="login-text">{I18n.t('end_of_free_trial')}</div>
      </form>
    );
  }

  renderFooter = () => {
    if (this.state.success) return <div />;

    const login = (
      <div style={{ marginBottom: 30, textAlign: 'center' }}>
        <Translate className="login-footer-text" value="you_already_have_an_account" />
        <RaisedButton
          style={style.secondaryButton}
          label={I18n.t('login_here')}
          labelColor={presonoTeal}
          labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
          href="https://my.presono.com/"
        />
      </div>
    );

    return (
      <div
        className="login-inner-content"
        style={{
          marginTop: 16, paddingTop: 28, paddingBottom: 28, backgroundColor: veryFaintAccentColor, fontSize: '14px',
        }}
      >
        {login}
        <p style={{ margin: 0, textAlign: 'center', color: accentColor25 }}>presono GmbH</p>
      </div>
    );
  }

  render() {
    if (this.langSwitcher) this.langSwitcher.closePopover();

    return (
      <Provider store={store}>
        <MuiThemeProvider muiTheme={presonoBaseTheme}>
          <div className="login">
            <div className="login-background" />
            <div className="login-inner">
              {this.renderHeader()}
              {this.devMode && this.renderDevMode()}
              {this.renderContent()}
              {this.renderFooter()}
            </div>
          </div>
        </MuiThemeProvider>
      </Provider>
    );
  }
}
