import React, { PureComponent } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export default class MailIcon extends PureComponent {
  render() {
    return (
      <SvgIcon viewBox="0 0 126.04 121.62" {...this.props}>
        <g id="Gruppe_1977" data-name="Gruppe 1977">
          <circle id="Ellipse_90" data-name="Ellipse 90" cx="56.81" cy="64.81" r="56.81" fill="#00323c" opacity="0.54" />
          <path id="Pfad_743" data-name="Pfad 743" d="M81.12,41.46H35.61A7.6,7.6,0,0,0,28,49V81.55a7.61,7.61,0,0,0,7.59,7.59H81.12a7.61,7.61,0,0,0,7.59-7.59V49a7.6,7.6,0,0,0-7.59-7.58Zm0,4.33a3.22,3.22,0,0,1,.81.11L58.37,64.69,35,45.86a3.7,3.7,0,0,1,.66-.07Zm0,39H35.61a3.25,3.25,0,0,1-3.25-3.25V49.34L57,69.15a2.17,2.17,0,0,0,2.71,0L84.38,49.49V81.55A3.26,3.26,0,0,1,81.12,84.8Z" transform="translate(-1.56 -0.49)" fill="#fff" />
        </g>
        <g id="Gruppe_1976" data-name="Gruppe 1976">
          <circle id="Ellipse_91" data-name="Ellipse 91" cx="103.54" cy="22.5" r="22.5" fill="#46bab9" />
          <g id="_1" data-name=" 1" style={{ isolation: 'isolate' }}>
            <text transform="translate(97.8 29.5)" fontSize="20" fill="#fff" fontFamily="Roboto-Bold, Roboto" fontWeight="700" style={{ isolation: 'isolate' }}>1</text>
          </g>
        </g>
      </SvgIcon>
    );
  }
}
