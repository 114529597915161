import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { syncTranslationWithStore, setLocale, loadTranslations } from 'react-redux-i18n';

import translations, { guessLanguage } from './../helpers/localisation';
import reducer from './reducers';

const configureStore = () => {
  const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
  const store = createStoreWithMiddleware(reducer);

  syncTranslationWithStore(store);

  store.dispatch(loadTranslations(translations));
  store.dispatch(setLocale(guessLanguage()));

  return store;
};

const store = configureStore();
export default store;
