import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import language from './language';


const rootReducer = combineReducers({
  language,
  i18n: i18nReducer,
});

export default rootReducer;
