import { handleActions } from 'redux-actions';

const reducer = handleActions({
  SET_CONTENT_LANGUAGES: (state, action) => (
    Object.assign({}, state, {
      contentLanguages: action.payload,
    })
  ),
}, { contentLanguages: [] });

export default reducer;
