export default {
  key: {
    AUTH_USER_ID: 'presono.auth.userId',
    AUTH_SESSION_TOKEN: 'presono.auth.sessionToken',
    AUTH_SESSION_TOKEN_EXPIRES: 'presono.auth.sessionTokenExpires',
    AUTH_REFRESH_TOKEN: 'presono.auth.refreshToken',

    PRESENTATION_KIOSK_MODE: 'presono.settings.kioskMode',
    PRESENTATION_KIOSK_RESET_TIME: 'presono.settings.kioskModeResetTime',
    PRESENTATION_KIOSK_AUTOPLAY: 'presono.settings.kioskModeAutoPlay',
    PRESENTATION_KIOSK_AUTOPLAY_TIME: 'presono.settings.kioskModeAutoPlayTime',

    PRESENTER_START_TIME: 'presono.presenter.startTime',
    PRESENTER_ACTIVE: 'presono.presenter.active',
    PRESENTER_ENTER_MENU: 'presono.presenter.enterMenu',
    PRESENTER_SCREEN_MUTE: 'presono.presenter.screenMute',
    PRESENTER_INSERT_SLIDE: 'presono.presenter.insertSlide',
    PRESENTER_INSERT_PRESENTATION: 'presono.presenter.insertPresentation',
    PRESENTER_BACK_TO_OVERVIEW: 'presono.presenter.backToOverview',
    PRESENTER_EXIT_PRESENTATION: 'presono.presenter.exitPresentation',
    PRESENTER_LANGUAGE_CHANGE: 'presono.presenter.languageChange',
    PRESENTATION: 'spectacle-slide',

    ANALYTICS_DATA_FILTERS: 'presono.analytics.data.filters',

    NEW_PRESENTATION: 'presono.presentation.create',

    LAST_WORKSPACE: 'presono.browser.lastSelectedWorkspace',
    IS_MAC: 'presono.isMac',

    OVERRIDE_LOCALE: 'presono.override_locale',

    SUPPRESS_LOGIN_DIALOG: 'presono.suppress_login_dialog',

  },
  get: key => window.localStorage.getItem(key),
  set: (key, value) => window.localStorage.setItem(key, value),
  remove: key => window.localStorage.removeItem(key),
};
