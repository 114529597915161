export default {
  iconSize: 24,
  appBarHeight: 64,
  editorToolbarHeight: 56,
  desktopGutter: 24,
  desktopGutterMore: 32,
  desktopGutterLess: 16,
  desktopGutterMini: 8,
  desktopKeylineIncrement: 64,
  desktopDropDownMenuItemHeight: 32,
  desktopDropDownMenuFontSize: 15,
  desktopDrawerMenuItemHeight: 48,
  desktopSubheaderHeight: 48,
  desktopToolbarHeight: 56,
  tabbarHeight: 52,
  sidebarWidth: 257,
  sidebarBrowser: 728,
  menuBarWidth: 52,
};
