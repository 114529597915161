import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { lightGrey, white } from '../values/colors';

const gradientColor = 'rgba(255, 255, 255, .15)';

const style = {
  progressWrapperStyle: {
    width: '100%',
    height: 48,
    backgroundColor: lightGrey,
    borderRadius: '0 0 2px 2px',
    overflow: 'hidden',
    position: 'relative',
  },
  progressDropValue: {
    color: white,
    fontSize: '0.7em',
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    width: '100%',
    textAlign: 'center',
  },
  progressText: {
    position: 'absolute',
    right: 24,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'right',
  },
};

export default class ProgressBar extends PureComponent {

  static propTypes = {
    color: PropTypes.string,
    value: PropTypes.number,
    active: PropTypes.bool,
    progressText: PropTypes.object,
    noDrop: PropTypes.bool,
    style: PropTypes.object,
  };

  render() {
    const progressValue = this.props.value ? Math.min(100, this.props.value) : 0;

    const progressBarStyle = {
      backgroundColor: this.props.color,
      width: `${progressValue}%`,
      height: '100%',
      animation: 'x 3s linear infinite',
      animationName: this.props.active ? 'animate-stripes' : null,
      backgroundSize: '30px 30px',
      transition: 'width 0.3s',
      backgroundImage: `linear-gradient(135deg, ${gradientColor} 25%, transparent 25%,
        transparent 50%, ${gradientColor} 50%, ${gradientColor} 75%, transparent 75%, transparent)`,
    };


    return (
      <div>
        <div style={{ ...style.progressWrapperStyle, ...this.props.style }}>
          <div style={progressBarStyle} />
          <div style={style.progressText}>
            <div style={{ fontSize: '0.9em' }}>{this.props.progressText ? this.props.progressText.main : ''}</div>
            <div style={{ fontSize: '0.8em' }}>{this.props.progressText ? this.props.progressText.sub : ''}</div>
          </div>
        </div>
      </div>
    );
  }
}
