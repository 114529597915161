import React, { PureComponent } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

export default class Check extends PureComponent {
  render() {
    return (
      <SvgIcon {...this.props}>
          
          <path d="M8,21.6l-7.7-7.7l3.5-3.5L8,14.6L20.2,2.4l3.5,3.5L8,21.6z"/>
      </SvgIcon>
    );
  }
}
