import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { accentColor15, veryFaintAccentColor, heavyAccentColor, accentColor, deepOrange800, veryFaintDeepOrange800, presonoTeal } from '../../values/colors';

const styles = {
  root: {
    border: `1px solid ${accentColor15}`,
    borderRadius: 2,
    backgroundColor: veryFaintAccentColor,
    color: heavyAccentColor,
    height: 48,
    paddingLeft: 12,
    paddingRight: 12,
    transition: 'border-color 0.3s ease-in',
    marginBottom: 24,
  },
  rootError: {
    borderColor: deepOrange800,
    backgroundColor: veryFaintDeepOrange800,
    color: deepOrange800,
  },
  rootFocus: {
    borderColor: accentColor,
  },
};

class LoginTextField extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    errorText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    onRef: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }


  render() {
    const { style, errorText, onRef, ...rest } = this.props;
    const { focused } = this.state;

    const hasError = !!errorText;
    const classNames = ['login-textfield'];

    let rootStyle = styles.root;

    if (focused) {
      rootStyle = { ...styles.root, ...styles.rootFocus };
    }

    if (hasError) {
      classNames.push('login-textfield-has-error');
      rootStyle = { ...styles.root, ...styles.rootError };
    }

    return (
      <TextField
        onFocus={() => { this.setState({ focused: true }); }}
        onBlur={() => { this.setState({ focused: false }); }}
        className={classNames.join(' ')}
        style={{ ...rootStyle, ...style }}
        inputStyle={{ marginTop: 0, paddingTop: 12, color: hasError ? deepOrange800 : heavyAccentColor, caretColor: presonoTeal }}
        floatingLabelStyle={{ top: 14, color: hasError ? deepOrange800 : accentColor }}
        floatingLabelShrinkStyle={{ transform: 'scale(0.75) translate(0px, -12px)', color: hasError ? deepOrange800 : accentColor }}
        errorStyle={{ color: deepOrange800, bottom: -4, fontSize: '12px', marginLeft: -12, marginRight: -12, lineHeight: 1.2 }}
        errorText={typeof errorText === 'boolean' ? '' : errorText}
        underlineShow={false}
        ref={onRef}
        {...rest}
      />
    );
  }
}

export default LoginTextField;
