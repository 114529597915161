import { I18n } from 'react-redux-i18n';

/**
 * allows react components/html elements to be placed inside translation strings
 * @returns array of components & plain strings
**/
export default function (id, object) {
  const keys = Object.keys(object);
  const placeholders = {};
  keys.forEach((key) => {
    placeholders[key] = `###~~~${key}~~~###`;
  });

  const text = I18n.t(id, placeholders);

  const splitText = text.split('###');
  keys.forEach((key) => {
    const idx = splitText.indexOf(`~~~${key}~~~`);
    if (idx !== -1) {
      splitText[idx] = object[key];
    }
  });

  return splitText;
}
