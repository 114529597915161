/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

import { List, ListItem } from 'material-ui/List';
import { Paper } from 'material-ui';

import OpenMenuIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';

import { languages, guessLanguage } from '../../helpers/localisation';

import { veryFaintAccentColor, accentColor, presonoTeal } from '../../values/colors';
import storage from '../../helpers/storage';

export default class MinimalUILanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    this.languages = [];
    Object.keys(languages).forEach((l) => {
      if (l !== 'moo') this.languages.push(languages[l]);
    });

    this.state = {
      currentLocale: guessLanguage(),
      popoverOpen: false,
    };
  }

  onSelectLocale = (locale) => {
    const { currentLocale } = this.state;
    if (locale === currentLocale) return;
    this.setState({ currentLocale: locale, popoverOpen: false });
    storage.set(storage.key.OVERRIDE_LOCALE, locale);
    window.location.reload();
  }

  closePopover = () => {
    if (this.state.popoverOpen) {
      this.setState({ popoverOpen: false });
    }
  }

  renderLanguage = lang => (
    <ListItem
      innerDivStyle={{ padding: 8 }}
      hoverColor={veryFaintAccentColor}
      key={lang.locale}
      onClick={this.onSelectLocale.bind(this, lang.locale)}
    >
      <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }} title={lang.display_name_original}>
        <span style={{ display: 'flex', flexDirection: 'column', color: lang.locale === this.state.currentLocale ? presonoTeal : accentColor }}>
          {lang.display_name}
        </span>
      </span>
    </ListItem>
  )


  renderPopover = () => (
    <List style={{ minWidth: '6rem', padding: 0 }}>
      {this.languages.map(lang => this.renderLanguage(lang))}
    </List>
  )

  render() {
    const { currentLocale, popoverOpen } = this.state;
    const currentLanguage = this.languages.find(l => l.locale === currentLocale);
    this.languages.sort(a => (a.locale === currentLocale ? -1 : 1));


    return (
      <div>
        <div
          onClick={() => { this.setState({ popoverOpen: !this.state.popoverOpen }); }}
          style={{ fontSize: '0.9em', cursor: 'pointer', transform: 'translateX(6px)', position: 'relative' }}
        >
          {currentLanguage && currentLanguage.display_name ? currentLanguage.display_name : I18n.t('Editor.RightHeader.selectLanguage')}
          <OpenMenuIcon color={accentColor} style={{ transform: 'translateY(6px)' }} />
        </div>
        <div
          style={{
            position: 'absolute',
            transform: 'translateX(-12px)',
            marginTop: 6,
            opacity: popoverOpen ? 1 : 0,
            transition: 'all 0.3s ease-out',
          }}
        >
          <Paper>
            {this.renderPopover()}
          </Paper>
        </div>
      </div>
    );
  }
}